<template>
  <!-- 添加编辑 -->
  <el-dialog
    :title="填写物流信息"
    width="30%"
    v-model="dialogShow"
  >
    <el-form :model="form" :rules="kdnameRules" ref="levelRef">
      <el-row>
        <el-col :span="18">
          <el-form-item label="物流公司" prop="kd_name">
            <el-input v-model="form.kd_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
       <el-col :span="18">
         <el-form-item label="物流单号" prop="kd_code">
           <el-input v-model="form.kd_code" />
         </el-form-item>
       </el-col>
      </el-row>

    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="btnCancel"> 取消 </el-button>
        <el-button type="primary" @click="btnOk"> 确认 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { saveDeilverAPI } from '@/api/card'
import { ElMessage } from 'element-plus'
const props = defineProps({
  // 接收 v-model
  modelValue: {
    type: Boolean,
    required: true
  },
  onDetail: {
    type: Function
  },

  deliverinfo: {
    type: Object
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const dialogShow = useVModel(props) // 得到一个响应式的数据，可以直接修改
watch(
  () => props.deliverinfo,
  (val) => {
    form.value = val
  },
  {
    deep: true
  }
)

const form = ref({
  kd_name: '',
  kd_code: ''
})

const kdnameRules = ref({
  kd_name: [
    {
      required: true,
      trigger: 'blur',
      message: '物流公司必填'
    }
  ],
  kd_code: [
    {
      required: true,
      trigger: 'blur',
      message: '物流单号必填'
    }
  ]
})

// 获得 Ref 实例
const levelRef = ref(null)
// 取消
const btnCancel = () => {
  dialogShow.value = false
}

// 确认
const btnOk = () => {
  levelRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      // 触发添加 api
      console.log(Number(form.value.id))
      if (Number(form.value.id)) {
        await saveDeilverAPI(form.value)
        ElMessage.success('发货成功')
      }
      props.onDetail()
    } catch (error) {
      console.log(error)
    } finally {
      dialogShow.value = false
    }
  })
}
</script>

<style></style>
